@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

:root {
    /* Colors */
    --white: #f7f7f7;

    /* Font size */
    --small: 5px;
    --medium: 17px;
    --big: 25px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

body {
    background: var(--white);
}