.center_header_banner {
    max-width: 1100px;
    height: 80vh;
    margin: 2em auto;
}

.title_banner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title {
    font-size: 5em;
}

.subtitle {
    font-size: 4em;
}